import sanityClient  from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'

export const client = new sanityClient({
projectId: 'qps37ze7',
dataset: 'production',
apiVersion:'2023-11-27',
useCdn: true,
token:'skxEerTZPmhYKaDmIdP6WQu4Q7rp1DCTtH8dnDWooA3hHPkWoTs5bZZPiXJRvud8AsxXQwPNYCYRHNgZSs9air2YQk3UQdeUbnhIplzpm628tpBdlWkeRxjBFwz1d8kYFiEBXrtbsHIz18uFKQwdmmiP5jbUQvMqbTTgmpqQxgYpqs8WSSpv', 
});

const builder = imageUrlBuilder(client);

export const urlFor = (source)=> builder.image(source);