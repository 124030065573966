import React, {useState, useEffect} from 'react'
import {  client } from '../../client'
import Head from '../../container/Head'
import "./Morals.css"

const Morals = () => {
  const  [moral, setMoral] = useState([])

  useEffect(() => {

    const query = '*[_type == "moral"]';
  
    client.fetch(query)
    .then((data)=>{
      setMoral(data);
    })
    
  }, [])
  return (
    <>
<Head name={'Moral Restriction'}/> 
<div className="cont-time" data-aos="fade-up">
    <div class="shadow p-3 mb-5 bg-white rounded media">
      <h1>Moral Restriction</h1>
      <div className="time">
        <ul>
          <li className='clock'> Last Updated</li>
          <li >  Nov 27, 2023</li>
          </ul>
       </div>
       {
     moral?.map((curElem)=>(
      <>
    <div className="principle_M_cont" data-aos="fade-up">
        <div className="pContent">
       <h2 className='moral-heading'>{curElem.name}</h2>
        {/* <h5>{curElem.stdname}</h5> */}
        <p>{curElem.message1}</p>
        <p>{curElem.message2}</p>
        <p>{curElem.message3}</p>
        <p>{curElem.message4}</p>
        <p>{curElem.message5}</p>
        <p>{curElem.message6}</p>
        <p>{curElem.message7}</p>
        <p>{curElem.message8}</p>
        <p>{curElem.message9}</p>
        <p>{curElem.message10}</p>
        </div>
    </div>
    </>
     ))
    }
        <hr />  
        </div>
        </div>
    </>
  )

}

export default Morals